<template>
  <div class="template-sheet elevation-5">
    <template v-for="section in template.sections">
      <template-component-editor
        v-if="mode === 'edit'"
        :key="section.id"
        :data="section"
        @save="saveSection"
        @delete="deleteSection"
      >
        <template v-slot:default="{ data }">
          <component
            :is="data.name"
            :value="data.value"
            class="template-section-container"
          />
        </template>
      </template-component-editor>
      <template v-else>
        <component
          :is="section.name"
          :key="section.id"
          :value="section.value"
          class="template-section-container"
        />
      </template>
    </template>
  </div>
</template>

<script>
import TemplateComponentEditor from './TemplateComponentEditor.vue'
import TextArea from './TemplateSectionComponents/TextArea.vue'

export default {
  components: {
    TemplateComponentEditor,
    TextArea
  },
  props: {
    template: {
      type: Object,
      required: true,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  methods: {
    saveSection (section) {
      const index = this.template.sections.findIndex((s) => s.id === section.id)
      this.$set(this.template.sections, index, section)
    },
    deleteSection (section) {
      const index = this.template.sections.findIndex((s) => s.id === section.id)
      this.template.sections.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-sheet {
    height: 75vh;
    overflow-y: auto;
    padding: 1rem;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0,0,0,0.1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.5);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0,0,0,0.8);
    }
  }
  .template-section-container {
    padding: 1rem;
    margin-bottom: 1rem;
  }
</style>
