<template>
  <div v-html="value" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '<h1>TextArea</h1>'
    }
  }
}
</script>
