<template>
  <v-container
    class="tempalte-component-edit"
    :class="{'in-edit': editMode}"
  >
    <slot
      v-if="!editMode"
      :data="data"
    />
  </v-container>
</template>

<script>
// import { McWysiwyg } from '@mycure/vue-wysiwyg'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    const innerData = {
      ...this.data
    }
    return {
      innerData,
      hasEdit: false,
      editMode: false,
      confirmEditdialog: false,
      deleteDialog: false
    }
  },
  methods: {
    toggleEditMode () {
      this.editMode = true
      console.error(this.innerData, 'innerData')
    },
    changeEdit () {
      this.hasEdit = true
    },
    leaveEditMode () {
      if (!this.hasEdit) {
        this.editMode = false
        return
      }
      this.confirmEditdialog = true
    },
    saveEdit () {
      this.$emit('save', this.innerData)
      this.editMode = false
    },
    exitConfirmEditDialog () {
      this.resetState()
      this.confirmEditdialog = false
      this.editMode = false
    },
    resetState () {
      this.innerData = {
        ...this.data
      }
    },
    deleteSection () {
      this.$emit('delete', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tempalte-component-edit {
    position: relative;
    padding: 0;
    border-radius: 6px;
    &:hover {
      &:not(.in-edit) {
        background-color: rgba(0,0,0,0.1);
        .tempalte-component-edit-btn, .tempalte-component-delete-btn {
          display: block;
        }
      }
    }
  }
  .tempalte-component-edit-btn, .tempalte-component-delete-btn {
    display: none;
    position: absolute;
    z-index: 2;
  }
  .tempalte-component-edit-btn {
    right: 4px;
    top: 4px;
  }
  .tempalte-component-delete-btn {
    right: 4px;
    bottom: 4px;
  }
</style>
